.carousel-slide {
  &--active {
    display: block;
    animation: slide-in-next 0.8s forwards;
  }

  &--hidden {
    display: none;
    animation: slide-out-next 0.8s forwards;
  }
}

@keyframes slide-in-next {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 100;
    transform: translateY(0%);
  }
}

@keyframes slide-out-next {
  0% {
    opacity: 100;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}