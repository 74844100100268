@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './typography.scss';

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Harmonia Sans';
    src: url('../fonts/HarmoniaSansProCyr-Regular.ttf') format('truetype');
}

@layer base {
    h1,
    h2,
    h3,
    h4,
    h5,
    button {
        font-family: 'Gilroy', Arial, sans-serif;
    }

    p,
    a,
    span {
        font-family: 'Harmonia Sans', Arial, sans-serif;
    }
}
