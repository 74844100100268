// Typography
.h1 {
    @apply text-4xl leading-tight tracking-tighter;
}

.h2 {
    @apply text-3xl leading-tight tracking-tighter;
}

.h3 {
    @apply text-3xl leading-none;
}

.h4 {
    @apply text-2xl leading-snug tracking-tight;
}

p {
    @apply my-4 text-gray-600;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

// Buttons
.btn,
.btn-sm {
    font-family: 'Gilroy', Arial, sans-serif;
    @apply font-medium inline-flex items-center justify-center rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
    @apply px-8 py-3 shadow-lg;
}

.btn-lg {
    font-family: 'Gilroy', Arial, sans-serif;
    @apply px-16 py-3 shadow-lg;
}

.btn-sm {
    @apply px-4 py-2;
}

.bg-effect {
    background: url('../assets/bg-effect.png');
    background-color: rgba(250, 0, 97, 0.17);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
}

.gilroy {
    font-family: 'Gilroy', Arial, sans-serif;
}

.harmonia {
    font-family: 'Harmonia Sans', Arial, sans-serif;
}
